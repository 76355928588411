import { PhotoCard } from "../components/photo-card";
import Center from "../components/ui/common/center";
import { css } from "@emotion/react";
import Padding from "../components/ui/common/padding";
import { useSearchParams, useNavigate } from "react-router-dom";
import Layout from "../components/ui/common/layout";
import AnimatedText from "../components/animated-text";
import { useState, useEffect } from "react";
import { usePocaApi } from "../client/api";
import Space from "../components/ui/common/space";
import ShareButton from "../components/share-button";
import LinkIcon from "../components/icon/link";
import share from "../utils/share";
import ChangeButton from "../components/change-button";
import ChangeIcon from "../components/icon/change";

function Home() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const api = usePocaApi();
  const [style, setStyle] = useState<"default" | "vivid">("default");
  const [result, setResult] = useState<{
    icon: string;
    type: "경품" | "꽝" | string;
    description: string;
  }>();
  const [data, setData] = useState<{
    url: string;
    name: string;
    jobTitle?: string;
  }>();

  const handleShare = async () => {
    const result = await share({
      title: "해킹그로스 포토카드 📸",
      text: "포토카드를 간직해보세요! 당첨된 카드라면 링크를 저장하고 있다가 교환할 수도 있어요",
      url: document.location.href,
    });
    if (result === "copiedToClipboard") {
      alert("링크를 클립보드에 복사했습니다.");
    } else if (result === "failed") {
      alert("공유하기가 지원되지 않는 환경입니다.");
    }
  };

  useEffect(() => {
    const key = searchParams.get("key");
    if (!key) {
      navigate("/");
      return;
    }

    (async () => {
      const imageUrl = await api.imageUrl(key);
      setData(imageUrl);

      const result = await api.item(key);
      setResult(result);
    })();
  }, [api, navigate, searchParams]);

  return (
    <Layout style={style}>
      <Padding>
        <Center>
          <div
            css={css`
              color: var(--color-purple);
              font-weight: 500;
              line-height: 28.64px;
              font-size: 22px;
              margin-bottom: 18px;
            `}
          >
            Guild-House Photo Card
          </div>
          <div
            css={css`
              margin-bottom: 6px;
            `}
          >
            해킹그로스 길드하우스
          </div>
          <div
            css={css`
              color: ${style === "default"
                ? "var(--color-light-green)"
                : "var(--color-purple)"};
              font-weight: 800;
              line-height: 28.64px;
              font-size: 32px;
              margin-bottom: 50px;
            `}
          >
            <AnimatedText text="카드를 뒤집어 보세요" />
          </div>
          {data && (
            <PhotoCard
              width="292px"
              height="411px"
              url={data.url}
              name={data.name}
              result={result}
            />
          )}
          <div
            css={css`
              width: 292px;
              padding-top: 8px;
            `}
          >
            <ChangeButton
              type={style}
              onClick={() => {
                if (style === "default") {
                  setStyle("vivid");
                } else setStyle("default");
              }}
            >
              <div
                style={{
                  paddingRight: "4px",
                  justifyContent: "center",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <ChangeIcon />
              </div>
              디자인 변경
            </ChangeButton>
          </div>
          <Space size="46px" />
          <ShareButton onClick={handleShare}>
            <div
              style={{
                paddingRight: "8px",
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <LinkIcon />
            </div>
            공유하기
          </ShareButton>
        </Center>
      </Padding>
    </Layout>
  );
}

export default Home;
