import { PhotoCard } from "../components/photo-card";
import Center from "../components/ui/common/center";
import { css } from "@emotion/react";
import Padding from "../components/ui/common/padding";
import StickyButton from "../components/sticky-button";
import { useNavigate } from "react-router-dom";
import NextIcon from "../components/icon/next";
import Layout from "../components/ui/common/layout";
import AnimatedText from "../components/animated-text";
import Space from "../components/ui/common/space";

function Home() {
  const navigate = useNavigate();

  return (
    <Layout>
      <Padding>
        <Center>
          <div
            css={css`
              color: var(--color-purple);
              font-weight: 500;
              line-height: 28.64px;
              font-size: 22px;
              margin-bottom: 18px;
            `}
          >
            Guild-House Photo Card
          </div>
          <div
            css={css`
              margin-bottom: 6px;
            `}
          >
            해킹그로스 길드하우스
          </div>
          <div
            css={css`
              color: var(--color-light-green);
              font-weight: 800;
              line-height: 28.64px;
              font-size: 32px;
              margin-bottom: 50px;
            `}
          >
            <AnimatedText text="나만의 포카 만들기" />
          </div>
          <PhotoCard
            width="292px"
            height="411px"
            url="https://digitalmarketingsummit.co.kr/wp-content/uploads/2023/03/%EC%A0%95%EC%84%B1%EC%98%81-330390.png"
          />
          <StickyButton onClick={() => navigate("/create")}>
            <div>
              지금 만들기 <NextIcon alpha="0.5" />
              <NextIcon />
            </div>
          </StickyButton>
        </Center>
        <Space size="46px" />
      </Padding>
    </Layout>
  );
}

export default Home;
