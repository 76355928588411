import { motion } from "framer-motion";

const AnimatedText = ({
  text,
  splitBy = " ",
  duration = 0.25,
}: {
  text: string;
  splitBy?: string;
  duration?: number;
}) => {
  return (
    <>
      {text.split(splitBy).map((el, i) => {
        return (
          <motion.span
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{
              duration,
              delay: i / 10,
            }}
            key={i}
          >
            {el}
            {splitBy}
          </motion.span>
        );
      })}
    </>
  );
};

export default AnimatedText;
