import { css } from "@emotion/react";
import styled from "@emotion/styled";

const Space = styled.div<{ size: string }>(({ size }: { size: string }) => {
  return css`
    height: ${size};
    width: 100%;
  `;
});

export default Space;
