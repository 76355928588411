import { css, Global } from "@emotion/react";

export const globalStyles = (
  <Global
    styles={css`
      #root {
        width: 100%;
        min-width: 320px;
        max-width: var(--max-width);
        display: flex;
        margin: 0 auto;
        flex-direction: column;
        min-height: 100vh;
        padding-top: var(--layout-padding-top);
        background-color: var(--color-black);
        color: var(--color-white);
      }

      @media (min-width: 600px) {
        #root:before,
        #root:after {
          content: "";
          display: block;
          width: 1px;
          height: 100%;
          position: fixed;
          top: 0;
          z-index: 9999;
        }
        #root:before {
          left: 50%;
          transform: translate(-300px);
        }

        #root:after {
          right: 50%;
          transform: translate(300px);
        }
      }
    `}
  />
);

export const vividGlobalStyles = (
  <Global
    styles={css`
      #root {
        width: 100%;
        min-width: 320px;
        max-width: var(--max-width);
        display: flex;
        margin: 0 auto;
        flex-direction: column;
        min-height: 100vh;
        padding-top: var(--layout-padding-top);
        background: linear-gradient(180deg, #48d2bf 0%, #d283ff 100%);
        color: var(--color-white);
      }

      @media (min-width: 600px) {
        #root:before,
        #root:after {
          content: "";
          display: block;
          width: 1px;
          height: 100%;
          position: fixed;
          top: 0;
          z-index: 9999;
        }
        #root:before {
          left: 50%;
          transform: translate(-300px);
        }

        #root:after {
          right: 50%;
          transform: translate(300px);
        }
      }
    `}
  />
);
