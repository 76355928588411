import styled from "@emotion/styled";

const TextInput = styled.input`
  padding: 0 16px;
  font-size: 16px;
  display: block;
  height: 48px;
  font-weight: 500;
  border: 1px solid var(--color-white);
  color: var(--color-white);
  border-radius: 10px;
  width: 100%;
  background-color: transparent;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: var(--color-gray);
  }
`;

export default TextInput;
