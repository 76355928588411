import { css } from "@emotion/react";
import Layout from "../components/ui/common/layout";
import Padding from "../components/ui/common/padding";
import Center from "../components/ui/common/center";
import CropIcon from "../components/icon/crop";
import TextInput from "../components/text-input";
import Space from "../components/ui/common/space";
import HiddenInput from "../components/hidden-input";
import { useRef, useState } from "react";
import Button from "../components/button";
import { usePocaApi } from "../client/api";
import axios, { AxiosProgressEvent } from "axios";
import { useNavigate } from "react-router-dom";
import AnimatedText from "../components/animated-text";

function CreatePage() {
  const naviage = useNavigate();
  const inputEl = useRef<HTMLInputElement>(null);
  const pocaApi = usePocaApi();
  const [name, setName] = useState<string>();
  const [jobTitle, setJobTitle] = useState<string>();
  const [imageUrl, setImageUrl] = useState<string>();
  const [imgFile, setFile] = useState<File>();
  const [loading, setLoading] = useState<boolean>(false);
  const [uploadProgress, setUploadProgress] = useState<number | null>(null);

  const onImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      console.log(event.target);
      const reader = new FileReader();

      const file = event.target.files[0]!;

      reader.readAsDataURL(file);
      reader.onload = () => {
        console.log({
          data: reader.result,
        });

        setFile(file);
        setImageUrl(reader.result as string);
      };
    }
  };

  const onImageUploadClick = () => {
    const input = inputEl.current;

    if (input) input.click();
  };

  const uploadImage = async () => {
    setLoading(true);
    if (!name) {
      alert("이름 입력은 필수입니다");
      setLoading(false);
      return;
    }

    if (!imgFile) {
      alert("이미지가 없습니다");
      setLoading(false);
      return;
    }

    const { key, body } = await pocaApi.prepareUpload({
      name,
      jobTitle: jobTitle || "알 수 없음",
      filePath: `${name}.png`,
    });

    const formData = new FormData();

    Object.keys(body.fields).forEach((key) =>
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      formData.append(key, body.fields[key])
    );

    formData.append("file", imgFile);

    const result = await axios.post(body.url, formData, {
      headers: { "Content-Type": "multipart/form-data" },
      onUploadProgress: (progressEvent: AxiosProgressEvent) => {
        if (progressEvent.lengthComputable) {
          const { loaded, total } = progressEvent;
          const percentCompleted = Math.round((loaded / total!) * 100);
          setUploadProgress(percentCompleted);
        }
      },
    });
    if (result.status === 204) {
      setLoading(false);
      naviage(`/my-card?key=${key}`);
    }
  };

  if (loading) {
    return (
      <Layout>
        <div
          css={css`
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 100vh;
          `}
        >
          <div>
            <AnimatedText
              duration={2.5}
              text={(() => {
                if (!uploadProgress) {
                  return "포토카드를 구우는 중 ...";
                }
                if (uploadProgress < 20) {
                  return "포토샵에서 사진을 보정하는 중 ...";
                } else if (uploadProgress < 60) {
                  return "사진을 인화하는 중 ...";
                } else if (uploadProgress < 80) {
                  return "카드에 반짝이 붙이는 중 ...";
                } else {
                  return "포토카드 선물 포장하는 중 ...";
                }
              })()}
            />
          </div>
        </div>
      </Layout>
    );
  }

  return (
    <Layout>
      <Padding>
        <Center>
          <div
            css={css`
              color: var(--color-white);
              font-weight: 400;
              line-height: 38px;
              font-size: 38px;
              margin-bottom: 5px;
            `}
          >
            Hacking Growth
          </div>
          <div
            css={css`
              color: var(--color-light-green);
              font-weight: 400;
              line-height: 24px;
              font-size: 24px;
              margin-bottom: 18px;
            `}
          >
            Guild-House Photo Card
          </div>
          <div
            css={css`
              width: 260px;
              justify-content: center;
              display: flex;
              flex-direction: column;
              align-items: center;
            `}
          >
            <TextInput
              placeholder="이름을 입력하세요 (영문 추천)"
              onChange={(e) => setName(e.target.value)}
            />
            <Space size="8px" />
            <TextInput
              placeholder="직무를 입력하세요 (영문 추천)"
              onChange={(e) => setJobTitle(e.target.value)}
            />
            <HiddenInput
              type="file"
              accept="image/*"
              ref={inputEl}
              onChange={onImageChange}
            />
          </div>
          <Space size="20px" />

          {imageUrl ? (
            <img
              onClick={onImageUploadClick}
              src={imageUrl}
              css={css`
                width: 292px;
                height: 411px;
                border-radius: 10px;
                cursor: pointer;
              `}
            />
          ) : (
            <div
              onClick={onImageUploadClick}
              css={css`
                width: 292px;
                height: 411px;
                border: 1px solid;
                border-style: dashed;
                border-radius: 10px;
                align-items: center;
                display: flex;
                cursor: pointer;
                flex-direction: column;
                justify-content: center;
              `}
            >
              <CropIcon />
              <span
                css={css`
                  font-size: 14px;
                  padding-top: 14px;
                `}
              >
                사진을 넣어주세요
              </span>
            </div>
          )}
          <Space size="20px" />
          <Button onClick={uploadImage} disabled={loading}>
            {loading ? "로딩 중" : "만들기"}
          </Button>
        </Center>
      </Padding>
    </Layout>
  );
}

export default CreatePage;
