import styled from "@emotion/styled";

const StickyButton = styled.div`
    position: fixed;
    bottom: 0;
    background: var(--color-green-purple);
    min-height: 50px;
    cursor: pointer;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    min-width: 320px;
    max-width: var(--max-width);
`

export default StickyButton