const LinkIcon = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_84_67)">
        <path
          d="M13.75 6.41669H16.5C17.1019 6.41669 17.6979 6.53524 18.2539 6.76557C18.81 6.99591 19.3153 7.33351 19.7409 7.75911C20.1665 8.18472 20.5041 8.68998 20.7344 9.24605C20.9647 9.80213 21.0833 10.3981 21.0833 11C21.0833 11.6019 20.9647 12.1979 20.7344 12.754C20.5041 13.3101 20.1665 13.8153 19.7409 14.2409C19.3153 14.6665 18.81 15.0041 18.2539 15.2345C17.6979 15.4648 17.1019 15.5834 16.5 15.5834H13.75M8.24996 15.5834H5.49996C4.89807 15.5834 4.30207 15.4648 3.74599 15.2345C3.18992 15.0041 2.68466 14.6665 2.25905 14.2409C1.39951 13.3814 0.916626 12.2156 0.916626 11C0.916626 9.78444 1.39951 8.61866 2.25905 7.75911C3.1186 6.89957 4.28438 6.41669 5.49996 6.41669H8.24996"
          stroke="#313132"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.33337 11H14.6667"
          stroke="#313132"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_84_67">
          <rect width="22" height="22" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LinkIcon;
