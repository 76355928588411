import styled from "@emotion/styled";

const ShareButton = styled.div`
  width: 127px;
  height: 46px;
  padding: 0 16px;
  font-weight: 500;
  cursor: pointer;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 14px;
  background-color: var(--color-white);
  color: var(--color-black);
  border-radius: 60px;
`;

export default ShareButton;
