import { css } from "@emotion/react";
import styled from "@emotion/styled";

const Button = styled.div<{ disabled?: boolean }>(
  ({ disabled = false }: { disabled?: boolean }) => css`
    width: 258px;
    height: 46px;
    padding: 0 16px;
    font-weight: 500;
    cursor: pointer;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 14px;
    background-color: var(--color-light-green);
    color: var(--color-black);
    border-radius: 10px;
    ${disabled
      ? "pointer-events: none; background-color: var(--color-light-gray);"
      : ""}
  `
);

export default Button;
