import axios, { AxiosInstance } from "axios";
import { useMemo } from "react";

class PocaApi {
  private readonly client: AxiosInstance;

  constructor() {
    this.client = this._createPocaApiClient();
  }

  _createPocaApiClient() {
    return axios.create({
      baseURL: import.meta.env.VITE_POCA_API_URL,
    });
  }

  async imageUrl(key: string) {
    const result = await this.client.get(`?key=${key}`);
    return result.data;
  }

  async item(key: string): Promise<{
    icon: string;
    type: "경품" | "꽝" | string;
    description: string;
  }> {
    const result = await this.client.get(`/deterimine?key=${key}`);
    return result.data;
  }

  async prepareUpload(payload: {
    name: string;
    jobTitle: string;
    filePath: string;
  }): Promise<{
    key: string;
    body: { url: string; fields: Record<string, unknown> };
  }> {
    const result = await this.client.post("/upload/prepare", payload);
    return result.data;
  }
}

export function usePocaApi() {
  return useMemo(() => new PocaApi(), []);
}
