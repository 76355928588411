const ChangeIcon = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_84_80)">
        <path
          d="M0.583252 2.33331V5.83331H4.08325"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M13.4167 11.6667V8.16669H9.91675"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M11.9524 5.25002C11.6566 4.41398 11.1538 3.66651 10.4909 3.07735C9.82804 2.48819 9.02673 2.07655 8.16175 1.88084C7.29677 1.68513 6.39631 1.71172 5.54439 1.95814C4.69247 2.20456 3.91686 2.66277 3.28992 3.29002L0.583252 5.83336M13.4166 8.16669L10.7099 10.71C10.083 11.3373 9.30737 11.7955 8.45545 12.0419C7.60353 12.2883 6.70307 12.3149 5.83809 12.1192C4.97311 11.9235 4.1718 11.5119 3.50894 10.9227C2.84608 10.3335 2.34327 9.58607 2.04742 8.75002"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_84_80">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ChangeIcon;
