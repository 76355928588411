import { css } from "@emotion/react";
import styled from "@emotion/styled";

const ChangeButton = styled.div<{ type: "vivid" | "default" }>(
  ({ type }: { type: "vivid" | "default" }) => {
    let color = "var(--color-dark-gray)";
    switch (type) {
      case "vivid":
        color = "rgba(255, 255, 255, 20%)";
        break;
      case "default":
      default:
        color = "var(--color-dark-gray)";
        break;
    }

    return css`
      width: 93px;
      height: 30px;
      padding: 0 16px;
      float: right;
      font-weight: 500;
      cursor: pointer;
      align-items: center;
      display: flex;
      flex-direction: row;
      justify-content: center;
      font-size: 12px;
      background-color: ${color};
      color: var(--color-white);
      border-radius: 8px;
    `;
  }
);

export default ChangeButton;
