import { motion, useSpring } from "framer-motion";
import { useState, useRef, useEffect } from "react";
import { PhotoCardBase } from "./photo-card.styles";
import { css } from "@emotion/react";

const spring = {
  type: "spring",
  stiffness: 300,
  damping: 40,
};

export function PhotoCard({
  width,
  height,
  url,
  result,
  name = "폴",
  jobTitle = "길드장",
}: {
  width: string;
  height: string;
  url: string;
  name?: string;
  jobTitle?: string;
  result?: {
    icon: string;
    type: "경품" | "꽝" | string;
    description: string;
  };
}) {
  const [isFlipped, setIsFlipped] = useState(false);

  const handleClick = () => {
    setIsFlipped((prevState) => !prevState);
  };

  const [rotateXaxis, setRotateXaxis] = useState(0);
  const [rotateYaxis, setRotateYaxis] = useState(0);
  const ref = useRef(null);

  const handleMouseMove = (event: { clientY: number; clientX: number }) => {
    const element = ref.current;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    const elementRect = element.getBoundingClientRect();
    const elementWidth = elementRect.width;
    const elementHeight = elementRect.height;
    const elementCenterX = elementWidth / 2;
    const elementCenterY = elementHeight / 2;
    const mouseX = event.clientY - elementRect.y - elementCenterY;
    const mouseY = event.clientX - elementRect.x - elementCenterX;
    const degreeX = (mouseX / elementWidth) * 20; //The number is the rotation factor
    const degreeY = (mouseY / elementHeight) * 20; //The number is the rotation factor
    setRotateXaxis(degreeX);
    setRotateYaxis(degreeY);
  };

  const handleMouseEnd = () => {
    setRotateXaxis(0);
    setRotateYaxis(0);
  };

  const dx = useSpring(0, spring);
  const dy = useSpring(0, spring);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    dx.set(-rotateXaxis);
    dy.set(rotateYaxis);
  }, [rotateXaxis, rotateYaxis]);

  return (
    <motion.div
      onClick={handleClick}
      transition={spring}
      style={{
        perspective: "1200px",
        transformStyle: "preserve-3d",
        width: `${width}`,
        height: `${height}`,
      }}
    >
      <motion.div
        ref={ref}
        whileHover={{ scale: 1.1 }} //Change the scale of zooming in when hovering
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseEnd}
        transition={spring}
        style={{
          width: "100%",
          height: "100%",
          rotateX: dx,
          rotateY: dy,
        }}
      >
        <div
          style={{
            perspective: "1200px",
            transformStyle: "preserve-3d",
            width: "100%",
            height: "100%",
          }}
        >
          <motion.div
            animate={{ rotateY: isFlipped ? -180 : 0 }}
            transition={spring}
            style={{
              width: "100%",
              height: "100%",
              zIndex: isFlipped ? 0 : 1,
              backfaceVisibility: "hidden",
              position: "absolute",
            }}
          >
            <PhotoCardBase url={url}>
              <div
                css={css`
                  height: 100%;
                  display: flex;
                  flex-direction: column;
                  align-items: flex-end;
                `}
              >
                <div
                  css={css`
                    margin-top: auto;
                    height: 20%;
                    width: 100%;
                    font-weight: 800;
                    font-size: 48px;
                    color: black;
                    font-family: var(--font-nanumpenscript);
                    text-decoration: underline;
                  `}
                >
                  <span
                    css={css`
                      padding-left: 10px;
                    `}
                  >
                    {name}
                  </span>
                  <div>{jobTitle}</div>
                </div>
              </div>
            </PhotoCardBase>
          </motion.div>
          <motion.div
            initial={{ rotateY: 180 }}
            animate={{ rotateY: isFlipped ? 0 : 180 }}
            transition={spring}
            style={{
              width: "100%",
              height: "100%",
              zIndex: isFlipped ? 1 : 0,
              backfaceVisibility: "hidden",
              position: "absolute",
            }}
          >
            <div
              css={css`
                width: 100%;
                height: 100%;
                border-radius: 8px;
                user-select: none;
                background: linear-gradient(180deg, #48d2bf 0%, #d283ff 100%);
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
              `}
            >
              <div
                css={css`
                  font-size: 62px;
                `}
              >
                {result?.icon ?? "❓"}
              </div>
              <div
                css={css`
                  font-size: 30px;
                  font-weight: 700;
                `}
              >
                {result?.type
                  ? `${result.type}${result.type === "꽝" ? "" : " 당첨"}`
                  : "무엇이 나올까요?"}
              </div>
              <div
                css={css`
                  padding-top: 12px;
                  font-size: 15px;
                  font-weight: 500;
                  line-height: 18.2px;
                  max-width: 180px;
                  text-align: center;
                `}
              >
                {result?.description ??
                  "카드를 뒤집으면 새로운 결과가 나올지도?"}
              </div>
            </div>
          </motion.div>
        </div>
      </motion.div>
    </motion.div>
  );
}
