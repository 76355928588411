import { globalStyles, vividGlobalStyles } from "./global-styles";

const Layout = ({
  style = "default",
  children,
}: {
  style?: string;
  children: React.ReactElement;
}) => {
  return (
    <>
      {
        {
          default: globalStyles,
          vivid: vividGlobalStyles,
        }[style || "default"]
      }
      {children}
    </>
  );
};

export default Layout;
